import { computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import useLocalStorage from '@/hooks/useLocalStorage';
import useStartupApplicationStore from './startup_application';

function defaultValueFn() {
  return {
    discardReason: null,
    discardReasonKeywords: null,
    discardFeedback: null,
  };
}

const useStartupApplicationDiscardStore = defineStore('startupApplicationDiscard', () => {
  const {
    currentStartupApplicationId: startupApplicationId,
  } = storeToRefs(useStartupApplicationStore());

  const discardData = useLocalStorage(
    `discard-data-${startupApplicationId.value}`,
    defaultValueFn(),
  );

  const discardReason = computed({
    get: () => discardData.value.discardReason,
    set: (newValue) => {
      discardData.value = { ...discardData.value, discardReason: newValue };
    },
  });

  const discardReasonKeywords = computed({
    get: () => discardData.value.discardReasonKeywords,
    set: (newValue) => {
      discardData.value = { ...discardData.value, discardReasonKeywords: newValue };
    },
  });

  const discardFeedback = computed({
    get: () => discardData.value.discardFeedback,
    set: (newValue) => {
      discardData.value = { ...discardData.value, discardFeedback: newValue };
    },
  });

  return {
    discardReason,
    discardReasonKeywords,
    discardFeedback,
  };
});

export { useStartupApplicationDiscardStore as default };

